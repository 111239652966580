import React, { useRef, useState, useEffect } from 'react';
import { FaFileSignature, FaSave, FaSyncAlt, FaPhotoVideo, FaUndo } from 'react-icons/fa';
import './formulario.css';
import API from '../../providers/api';
import { Row, Col, Spinner, Input, Button } from 'reactstrap'
import logo from '../../assets/img/logo.png'
import imagenLeft from '../../assets/img/imagen-left.png'
import success from '../../assets/img/success.png'
import fail from '../../assets/img/fail.png'
import { alert } from '../../helpers/alert';
import stateSelect from '../JsonData/state.json'

const Formulario = () => {

    const queryParameters = new URLSearchParams(window.location.search)
    const app = queryParameters.get("app")
    console.log(app);
    const [nombreForm, setNombreForm] = useState('')
    const [apellidoForm, setApellidoForm] = useState('')
    const [telefonoForm, setTelefonoForm] = useState('')
    const [correoForm, setCorreoForm] = useState('')
    const [regionForm, setRegionForm] = useState('')
    const [regionNameForm, setNameRegionForm] = useState('')
    const [comentarioForm, setComentarioForm] = useState('')
    const [save, setSave] = useState(false)
    const [loading, setLoading] = useState(false)

    const guardar = () => {
        if(nombreForm == '')
            return alert('Advertencia!', `Digite su nombre`, 'warning', 'Cerrar');
        if(apellidoForm == '')
            return alert('Advertencia!', `Digite sus apellidos`, 'warning', 'Cerrar');
        if(telefonoForm == '')
            return alert('Advertencia!', `Digite su teléfono`, 'warning', 'Cerrar');
        if(correoForm == '')
            return alert('Advertencia!', `Digite su correo electrónico`, 'warning', 'Cerrar');
        if(regionForm == '')
            return alert('Advertencia!', `Seleccione el estado`, 'warning', 'Cerrar');
        setLoading(true)
        API.leadDispositions(app, nombreForm, apellidoForm, telefonoForm, correoForm, regionNameForm, comentarioForm != '' ? comentarioForm : '-')
        .then(data => data.json())
        .then(dataJson => {
            if (dataJson.success == true) {
                setSave(true)
                alert('Éxito!', dataJson.message, 'success', 'Cerrar');
                limpiarDatos()
            }else{
                alert('Advertencia!', dataJson.message, 'warning', 'Cerrar');
            }
            setLoading(false)
        })
        .catch((e) => {
            console.log("Error =>", e)
        })
    }

    const limpiarDatos = () => {
        setNombreForm('')
        setApellidoForm('')
        setTelefonoForm('')
        setCorreoForm('')
        setRegionForm('')
        setComentarioForm('')
    }

    const reiniciar = () => {
        limpiarDatos()
        setSave(false)
    }

    const onchangeRegion = (e) => {
        let index = e.target.selectedIndex;
        setRegionForm(e.target.value);
        setNameRegionForm(e.target.options[index].text);
    }

    return (
        <div className='flex-container'>
            <div>
                <div className='cardForm'>
                    <div style={{borderRadius:'60px'}}>
                        <Row>
                            <Col lg='6'>
                                <img src={imagenLeft} alt='Imagen left' style={{width:'100%',borderRadius: '15px 0 0 15px'}} />
                            </Col>
                            <Col  lg='6' className='p-4'>
                                {
                                !save ?
                                    <>
                                        <Row className='mt-4'>
                                            <Col className='text-center'>
                                                <img src={logo} alt='logo' />
                                            </Col>
                                        </Row>
                                        <Row className='mt-4'>
                                            <Col className='text-center'>
                                                <span className='labelForm'>Infórmate sobre tu Seguro de Salud</span><br/>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className='text-center'>
                                                <hr style={{color:'red', width:'15%', height:'2px', marginLeft:'43%'}} />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <span>Protégete a ti y a tu familia con un seguro de salud que se adapta a tus necesidades. ¡Es fácil y rápido!</span>
                                            </Col>
                                        </Row>
                                        <Row className='mt-3'>
                                            <Col lg='6' md='12'>
                                                <label className='labelForm'>Nombre <span style={nombreForm === "" ? { color: 'red' } : {}}>{nombreForm === "" ? "*" : ""}</span></label>
                                                <Input className='input' placeholder='Nombre' value={nombreForm} onChange={e => setNombreForm(e.target.value)} />
                                            </Col>
                                            <Col  lg='6' md='12'>
                                                <label className='labelForm'>Apellido <span style={apellidoForm === "" ? { color: 'red' } : {}}>{apellidoForm === "" ? "*" : ""}</span></label>
                                                <Input className='input' placeholder='Apellido' value={apellidoForm} onChange={e => setApellidoForm(e.target.value)} />
                                            </Col>
                                        </Row>
                                        <Row className='mt-3'>
                                            <Col  lg='6' md='12'>
                                                <label className='labelForm'>Número de teléfono <span style={telefonoForm === "" ? { color: 'red' } : {}}>{telefonoForm === "" ? "*" : ""}</span></label>
                                                <Input className='input' placeholder='Número de teléfono' value={telefonoForm} onChange={e => setTelefonoForm(e.target.value)} />
                                            </Col>
                                            <Col  lg='6' md='12'>
                                                <label className='labelForm'>Correo electrónico <span style={correoForm === "" ? { color: 'red' } : {}}>{correoForm === "" ? "*" : ""}</span></label>
                                                <Input className='input' placeholder='Correo electrónico' value={correoForm} onChange={e => setCorreoForm(e.target.value)} />
                                            </Col>
                                        </Row>
                                        <Row className='mt-3'>
                                            <Col>
                                                <label className='labelForm'>Estado <span style={regionForm === "" ? { color: 'red' } : {}}>{regionForm === "" ? "*" : ""}</span></label>
                                                <Input className='input' type='select' value={regionForm} onChange={e => onchangeRegion(e)}>
                                                    <option value=''>Seleccione</option>
                                                    {
                                                        stateSelect.data.map((info)=>(
                                                            <option value={info.id}>{info.name}</option>
                                                        ))
                                                    }
                                                </Input>
                                            </Col>
                                        </Row>
                                        <Row className='mt-3'>
                                            <Col>
                                                <label className='labelForm'>Comentario adicional</label>
                                                <Input className='input' type='textarea' placeholder='Comentario adicional' value={comentarioForm} onChange={e => setComentarioForm(e.target.value)} />
                                            </Col>
                                        </Row>
                                        {
                                            !loading ?
                                                <Row className='mt-3 text-center'>
                                                    <Col>
                                                        <Button color='success' onClick={guardar}><FaSave /> Guardar información</Button>
                                                    </Col>
                                                </Row>
                                            : <Spinner color='red' />
                                        }
                                    </>
                                :
                                <Row className='text-center'>
                                    <Col>
                                        <img src={success} alt="success" style={{width:'200px',maxWidth:'100%'}} /><br/><span className='labelForm'>Información enviada satisfactoriamente</span>
                                        <br/>
                                        <Button className='mt-3' color='primary' onClick={reiniciar}><FaUndo /> Reiniciar Formulario</Button>
                                    </Col>
                                </Row>
                                }
                            </Col>
                        </Row>
                    </div>
                </div>

                <div>
                    <Row className='mt-3 text-center text-white'>
                        <Col>
                            <span>
                                Nos comprometemos a proteger tu privacidad. Los datos que proporciones serán utilizados únicamente para contactarte con información sobre nuestros seguros de salud y no serán compartidos con terceros. Tu seguridad y confianza son nuestra prioridad.
                            </span>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    );
}

export default Formulario;