import URL_ROUTE from '../constants/index';

class Api {
    async leadDispositions(app, nameForm, apellidoForm, telefonoForm, correoForm, regionForm, comentarioForm) {
        let data = {
            app, nameForm, apellidoForm, telefonoForm, correoForm, regionForm, comentarioForm
        }
        const query = await fetch(`${URL_ROUTE.LEADDISPOSITIONS}`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

}

export default new Api();
